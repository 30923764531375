import React, { useState, useEffect } from 'react'
import '../style/trendingDis.css'
import { MessageCircle, Bookmark, Send, MoreHorizontal } from 'lucide-react'
import Axios from 'axios'
import MediaDisplay from './Trending Discussions/MediaDisplay'
import verified from '../assets/verified.jpg'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useNavigate } from 'react-router-dom'
//import star-light.svg file from assets folder
import starLight from '../assets/icons/star-light.svg'

const TrendingDiscussions = (props) => {
  const navigate = useNavigate()
  dayjs.extend(relativeTime)

  const [data, setData] = useState([])

  const FormattedText = ({ textWithNewlines }) => {
    const processedText = textWithNewlines ? textWithNewlines.replace(/\n/g, '<br>') : '';
    return <span dangerouslySetInnerHTML={{ __html: processedText }} />;
  };

  const Card = (cardProps) => {
    const [detailed, setDetailed] = useState(true)
    return (
      <div
        className={`card-5 ${detailed ? 'card-5-detailed' : 'card-5-short'}`}
      >
        <div className={`${detailed ? 'card-5-description' : 'none'}`}>
          <div className='card-5-sect card-5-sect-1' style={{ display: 'flex', alignItems: 'center' }} onClick={() => navigate('/download')}>
            {cardProps.ele.is_anonymous === 1 ? <div className='card-5-sect-1-img' style={{ backgroundColor: "#F7B500" }}></div> : <img
              src={cardProps.ele.user_profile_picture}
              alt='profile'
              className='card-5-sect-1-img'
            />}

            <div style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              {cardProps.ele.is_anonymous === 1 ? <span className='bold flex-center'>{cardProps.ele.anonymous_id}</span> :
                <span className='bold flex-center'>
                  {cardProps.ele.user_alias} {cardProps.ele.expertise_name !== null ? `(${cardProps.ele.expertise_name})` : ''}
                  {cardProps.ele.user_verified == true &&
                    <img className='card-5-sect-3-icon' src={verified} alt='' style={{
                      color: 'blue',
                      margin: 'auto 5px',
                      height: '20px',
                      aspectRatio: '1/1'
                    }} />}
                </span>}

              <span className={cardProps.ele.group_alias === null ? `none` : ''}>
                /{cardProps.ele.group_alias}
              </span>
            </div>
            <MoreHorizontal className='card-5-sect-1-icon left-auto' style={{
              marginLeft: 'auto',
            }} />
          </div>

          {cardProps.ele.post_media.length !== 0 &&
            <div className='card-5-sect card-5-sect-2' onClick={() => navigate('/download')}>
              <MessageCircle className='card-5-sect-1-icon'
                onClick={() => navigate('/download')} />
              {/* <Bookmark className='card-5-sect-1-icon'
                onClick={() => navigate('/download')} /> */}
              <img src={starLight} alt='star-light' className='card-5-sect-1-icon' />
              <Send className='card-5-sect-1-icon'
                onClick={() => navigate('/download')} />
              <span className='left-auto'>{cardProps.ele.total_reply} Comments</span>
            </div>
          }
          {cardProps.ele.post_text &&
            <div className='card-5-sect card-5-sect-3'>
              {/* <span style={{
                display: 'inline-flex',
                fontWeight: 'bold',
                paddingRight: 5
              }}>
                {cardProps.ele.user_alias}
              </span> */}
              <span>
                <FormattedText textWithNewlines={cardProps.ele.post_text} />
              </span>
            </div>
          }
          {cardProps.ele.post_media.length === 0 &&
            <div className='card-5-sect card-5-sect-2' onClick={() => navigate('/download')}>
              <MessageCircle className='card-5-sect-1-icon'
                onClick={() => navigate('/download')} />
              {/* <Bookmark className='card-5-sect-1-icon'
                onClick={() => navigate('/download')} /> */}
              <img src={starLight} alt='star-light' className='card-5-sect-1-icon' />
              <Send className='card-5-sect-1-icon'
                onClick={() => navigate('/download')} />
              <span className='left-auto'>{cardProps.ele.total_reply} Comments</span>
            </div>
          }

          {/* <div
            className='highlight-1'
            onClick={() => {
              setDetailed(!detailed)
            }}>
            <u>see less</u>
          </div> */}

          <div className='card-5-sect card-5-sect-4'>
            {dayjs(cardProps.ele.posted_at).fromNow()}
          </div>
        </div>
        {/* <div className={`${!detailed ? '' : 'none'}`}>
          <div className={`card-5-text`}>
            {cardProps.ele.post_text}
          </div>

          <div
            className='highlight-1'
            onClick={() => {
              setDetailed(!detailed)
            }}>
            <u>see more</u>
          </div>
        </div> */}
        <div className='card-5-media'>
          <MediaDisplay
            media={cardProps.ele.post_media ? cardProps.ele.post_media : []}
            detailed={detailed}
            autoplay
            controls
          />
        </div>
      </div>
    )
  }

  // implement pagination for the trending discussions
  // Axios.get(`https://prd-api-1.phnk.in/post/get_public_timeline`, {
  //   headers: {
  //     'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjozODA0NDAyOTAsImlhdCI6MTY5MzIyNDM5OCwiZXhwIjoxNzc5NTM3OTk4fQ.kY2CNrB4nfyN16Sh-Q2Wbkwf6O06X2Cj69UAgCECQj8`
  //   }
  // }).then((res) => {
  //   setData(res.data.data.result)
  // }).catch((err) => {
  //   console.log(err)
  // })
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [limit, setLimit] = useState(20)

  const fetchTimeline = async () => {
    try {
      const response = await Axios.get(`https://prd-api-1.phnk.in/post/get_public_timeline`, {
        params: { page, limit },
        headers: {
          'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjozODA0NDAyOTAsImlhdCI6MTY5MzIyNDM5OCwiZXhwIjoxNzc5NTM3OTk4fQ.kY2CNrB4nfyN16Sh-Q2Wbkwf6O06X2Cj69UAgCECQj8`
        }
      });
  
      const newData = response.data.data.result;
  
      setData((prevData) => [...prevData, ...newData]); // Append new data
      if (newData.length < limit) {
        setHasMore(false); // No more data to fetch
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };


  const loadMoreData = async () => {
    if (loading || !hasMore) return;
  
    setLoading(true);
    await fetchTimeline();
    setPage((prevPage) => prevPage + 1); // Increment page after successful fetch
  };

  useEffect(() => {
    if (loading) return;
    if (page === 1) {
      loadMoreData();
    }
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 300) {
        loadMoreData(); // Trigger pagination
      }
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll); // Clean up
    };
  }, [loading, hasMore]);

  if (props.data.length !== 0) {
    return (
      <div className='trending-discussion'>
        (searched data)
        {props.data.map((ele, index) => {
          return (<Card key={index} ele={ele} />)
        })}
      </div>
    )
  }
  return (
    <div className='trending-discussion'>
      {data.length !== 0 ? <>
        {data.map((ele, index) => {
          return (<Card key={index} ele={ele} />)
        })}
        </>
        : <></>}
    </div>
  )
}

export default TrendingDiscussions
